/**
 * 字典管理接口
 */
import axiosApi from '@/service/modules/axios-api'

// 查询字典列表
export function listDict(params) {
  return axiosApi.get('/dict/page', params)
}

// 查询字典详细
export function getDict(params) {
  return axiosApi.get('/dict/getById', params)
}

// 新增字典
export function addDict(data) {
  return axiosApi.post('/dict/add', data)
}

// 修改字典
export function updateDict(data) {
  return axiosApi.post('/dict/edit', data)
}

// 删除字典
export function delDict(data) {
  return axiosApi.post('/dict/remove', data)
}

// 根据字典分类获取其标准代码
export function getDictList(dictGroupCode) {
  return axiosApi.get('/dict/getByDictGroupCode', { dictGroupCode: dictGroupCode })
}
