/**
 * 登录登出模块接口
 */
import axiosApi from '../../modules/axios-api'
import { MAIN_MICRO_SERVICE_PREFIX } from '../../index'
// 分页查询
export function queryPagination(params) {
  return axiosApi.get(`${ MAIN_MICRO_SERVICE_PREFIX }/notice/page`, params)
}
// 新增公告
export function addNotification(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/notice/add`, data)
}
// 编辑公告
export function editNotification(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/notice/edit`, data)
}
// 公告详情
export function notificationDetail(params) {
  return axiosApi.get(`${ MAIN_MICRO_SERVICE_PREFIX }/notice/getById`, params)
}
// 批量删除
export function batchNoteDel(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/notice/remove`, data)
}
