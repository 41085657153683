<template>
  <div class="mng-container">
    <mng-header :page-title="isEdit?'编辑公告':'新增公告'" msg="">
      <el-button size="small" @click="goBack">取 消</el-button>
      <el-button type="primary" size="small" @click="submitBtn" :loading="submitLoading">提 交</el-button>
    </mng-header>
    <div class="mng-body">
      <div class="form-area">
        <el-form :model="dataForm" :rules="rules" ref="dataForm" label-suffix=":" label-width="100px">
          <div class="form-item-title">基本信息</div>
          <el-form-item label="公告标题" prop="noticeTitle">
            <el-input v-model="dataForm.noticeTitle" maxlength="100" show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label="公告简述" prop="noticeBrief">
            <el-input type="textarea" rows="5" v-model="dataForm.noticeBrief" maxlength="200" show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="公告类型" prop="noticeType">
            <el-select :loading="loadingNoteTypeList" loading-text="正在拼命加载中,请稍等..."
                       :placeholder="loadingNoteTypeList?'加载中...':'请选择公告类型'" filterable clearable
                       v-model="dataForm.noticeType">
              <template v-if="loadingNoteTypeList" slot="prefix">
                <i class="el-icon-loading"></i>
              </template>
              <el-option v-for="item in noteTypeList" :label="item.dictLabel" :value="item.dictValue" :key="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态" prop="region">
            <el-switch
              v-model="dataForm.status"
              active-value="1"
              inactive-value="0">
            </el-switch>
          </el-form-item>
          <div class="form-item-title required-title">公告内容</div>
          <el-form-item label="" prop="noticeDetails">
            <tg-editor ref="editor" :html="dataForm.noticeDetails" :editor-config="editorConfig"
                       :toolbar-config="toolbarConfig" @onEditorChange="onEditorChange"></tg-editor>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import MngHeader from '@/components/mng-pages/MngHeader'
import TgEditor from '@/components/editor/TgEditor'
import { addNotification, editNotification, notificationDetail } from '@/service/api/notification/notification'
import { checkTextDesc } from '@/util/validate/text-validator'
import { getDictList } from '@/service/api/dict/dict-api'

export default {
  name: 'NotificationMng',
  components: {
    MngHeader,
    TgEditor
  },
  props: {
    pageData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    const checkTitle = (rules, value, callback) => {
      return checkTextDesc(true, '公告标题', rules, value, callback, 2, 100)
    }
    const checkBrief = (rules, value, callback) => {
      return checkTextDesc(false, '公告简述', rules, value, callback, 2, 200)
    }
    return {
      isEdit: false,
      submitLoading: false,
      loadingNoteTypeList: false,
      noteTypeList: [],
      // 表单数据
      dataForm: {
        noticeTitle: '',
        noticeBrief: '',
        noticeType: '',
        status: '',
        noticeDetails: ''
      },
      rules: {
        noticeTitle: [{
          required: true,
          validator: checkTitle,
          trigger: 'change'
        }],
        noticeBrief: [{
          required: false,
          validator: checkBrief,
          trigger: 'change'
        }],
        noticeType: [{
          required: true,
          message: '请选择公告类型',
          trigger: 'change'
        }],
        noticeDetails: [{
          required: true,
          validator: (rules, value, callback) => {
            console.log(value)
            const exp = /^(<p><br><\/p>)$/
            const exp2 = /^(<p>[<br>|&nbsp;|\s]*<\/p>)+$/
            if (!value || exp.test(value)) {
              this.$message.error('请输入公告内容')
            } else if (exp2.test(value)) {
              this.$message.error('公告内容不能为空白字符（空格，换行符等）')
            } else {
              return callback()
            }
          },
          trigger: 'change'
        }]
      },
      // 编辑器配置
      toolbarConfig: {
        excludeKeys: ['fullScreen', 'uploadVideo', 'uploadImage']
      },
      editorConfig: {
        readOnly: false,
        MENU_CONF: {
          uploadImage: {
            server: '',
            base64LimitSize: 10 * 1024 * 1024 // 1MB
          }
        }
      }
    }
  },
  mounted() {
    if (this.pageData && this.pageData.id) {
      this.isEdit = true
      this.getNoteDetail(this.pageData.id)
    }
    this.getNoteTypeList()
  },
  methods: {
    goBack() {
      this.$emit('changePage', { pageId: 'NotificationTable' })
    },
    submitBtn() {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.isEdit) {
            editNotification(this.dataForm).then(res => {
              this.submitLoading = false
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '编辑公告成功！')
                this.goBack()
              } else {
                this.$message.error(res.data.msg || '编辑公告失败！')
              }
            }).catch(e => {
              this.submitLoading = false
              this.$message.error('服务异常。')
            })
          } else {
            addNotification(this.dataForm).then(res => {
              this.submitLoading = false
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '新增公告成功！')
                this.goBack()
              } else {
                this.$message.error(res.data.msg || '新增公告失败！')
              }
            }).catch(e => {
              this.submitLoading = false
              this.$message.error('服务异常。')
            })
          }
        }
      })
    },
    // 公告类型枚举列表初始化
    getNoteTypeList() {
      this.loadingNoteTypeList = true
      getDictList('notification_type').then(res => {
        if (res.data.status === 200) {
          this.noteTypeList = res.data.data
        }
        this.loadingNoteTypeList = false
      }).catch(e => {
        console.log(e)
        this.loadingNoteTypeList = false
      })
    },
    // 获取公告详情
    getNoteDetail(id) {
      this.detailLoading = true
      notificationDetail({ id: this.pageData.id }).then(res => {
        this.detailLoading = false
        if (res.data.status === 200) {
          this.dataForm = res.data.data
        } else {
          this.$message.error(res.data.msg || '获取公告详情失败！')
        }
      }).catch(e => {
        console.log(e)
        this.detailLoading = false
        this.$message.error('服务异常！')
      })
    },
    // 编辑器内容变化
    onEditorChange(val) {
      this.dataForm.noticeDetails = val.html
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/common/mng-pages.scss";
</style>
