// 表单校验函数集锦

// 长度校验核心函数
const checkLength = function (label, value, callback, min, max) {
  if (min && value.length < min) {
    return callback(new Error(`${label}至少${min}个字符`))
  }
  if (max && value.length > max) {
    return callback(new Error(`${label}不能超过${max}个字符`))
  }
}

/**
 * 名称文本校验
 * checkNull:是否进行非空校验。false:不进行非空校验(空值也合法)；true:需要进行非空校验
 * label：被校验的字段中文名称
 * rule, value, callback是elementUI表单校验回调函数的入参
 */
export const checkTextName = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkNameCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkNameCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

// 名称文本校验核心函数
const checkNameCore = function (label, rule, value, callback, min, max) {
  const exp = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}仅支持汉字、英文字母、数字或下划线`))
  }
  if (value.indexOf('_') === 0 || value[value.length - 1] === '_') {
    return callback(new Error(`${label}不能以下划线开头或结尾`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 文章标题，资讯标题等文本校验（含中文常规标点符号）
 * checkNull:是否进行非空校验。false:只对有值时的字符串进行校验；true:需要进行非空校验
 * label：被校验的字段中文名称
 * rule, value, callback是elementUI表单校验回调函数的入参
 */
export const checkArticleName = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkArticleNameCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkArticleNameCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

// 文章标题、资讯标题等文本校验核心函数
const checkArticleNameCore = function (label, rule, value, callback, min, max) {
  const exp = /^[0-9\u4E00-\u9FA5A-Za-z_，。！”“…‘’；【】？（）《》—]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}仅支持中文(含常规标点符号)英文字母、数字或下划线`))
  }
  if (value.indexOf('_') === 0 || value[value.length - 1] === '_') {
    return callback(new Error(`${label}不能以下划线开头或结尾`))
  }
  checkTextDescCore(label, rule, value, callback, min, max)
  checkLength(label, value, callback, min, max)
}

/**
 * 账号文本校验
 * checkNull:是否进行非空校验。false:只对有值时的字符串进行校验；true:需要进行非空校验
 * label：被校验的字段中文名称
 * rule, value, callback是elementUI表单校验回调函数的入参
 */
export const checkAccount = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkAccountCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkAccountCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

// 编号文本校验核心函数
const checkAccountCore = function (label, rule, value, callback, min, max) {
  const exp = /^[A-Za-z0-9_-]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}仅支持英文、数字或下划线`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 编号文本校验
 * checkNull:是否进行非空校验。false:只对有值时的字符串进行校验；true:需要进行非空校验
 * label：被校验的字段中文名称
 * rule, value, callback是elementUI表单校验回调函数的入参
 */
export const checkTextNumber = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkTextNumberCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkTextNumberCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

// 编号文本校验核心函数
const checkTextNumberCore = function (label, rule, value, callback, min, max) {
  const exp = /^[A-Za-z0-9_]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}仅支持英文字母或数字字符`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 描述、备注等文本校验
 * checkNull:是否进行非空校验。false:只对有值时的字符串进行校验；true:需要进行非空校验
 * label：被校验的字段中文名称
 * rule, value, callback是elementUI表单校验回调函数的入参
 */
export const checkTextDesc = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkTextDescCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkTextDescCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

// 描述、备注等文本校验核心函数
const checkTextDescCore = function (label, rule, value, callback, min, max) {
  const exp = /\S+/
  const exp2 = /^[,./;'，。！”“…‘’；【】？（）《》— ]+$/
  const exp3 = /^[\u4E00-\u9FA5A-Za-z0-9“《]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}不能是空白字符(纯空格、制表符等)`))
  }
  checkLength(label, value, callback, min, max)
  if (exp2.test(value)) {
    return callback(new Error(`${label}不能是纯标点符号`))
  }
  if (!exp3.test(value.substring(0, 1))) {
    return callback(new Error(`${label}只能以中英文或数字开头`))
  }
}

/**
 * wangEditor5.0版本编辑器html内容非空校验
 */
export const checkEditorHtml = function (label, rule, value, callback) {
  const exp = /^(<p><br><\/p>)$/
  const exp2 = /^(<p>[<br>|&nbsp;]*<\/p>)+$/
  if (!value || exp.test(value)) {
    return callback(new Error(`请输入${label}`))
  }
  if (exp2.test(value)) {
    return callback(new Error(`${label}不能为空白字符（空格，换行符等）`))
  }
  return callback()
}

/**
 * 版本号格式校验
 */
export const checkVersionText = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkVersionTextCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkVersionTextCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

const checkVersionTextCore = function (label, rule, value, callback, min, max) {
  // const exp = /^([0-9]\d|[0-9])(\.([0-9]\d|\d)){1,2}$/
  const exp = /^(\d+)(\.(\d+)){1,2}$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}格式有误（正确格式例如：0.0或0.0.0）”`))
  }
  const tempArr = value.split('.')
  if (tempArr.some(item => Number(item) > 99)) {
    return callback(new Error(`${label}任意级别编号不能超过99”`))
  }
}

/**
 * 人名，用户名格式校验
 */
export const checkUserName = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkUserNameCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkUserNameCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

const checkUserNameCore = function (label, rule, value, callback, min, max) {
  const exp = /^[\u4E00-\u9FA5A-Za-z0-9_\\-]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}仅支持汉字、英文字母、数字或中下划线`))
  }
  if (value.indexOf('_') === 0 || value[value.length - 1] === '_') {
    return callback(new Error(`${label}不能以下划线开头或结尾`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 文件名称格式校验
 */
export const checkFileName = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkFileNameCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkFileNameCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}

const checkFileNameCore = function (label, rule, value, callback, min, max) {
  const exp = /\S+/
  const exp2 = /^[\u4E00-\u9FA5A-Za-z0-9《》【】{}!@#$%&*(),，。；\]：:;'?\\[."\\-]+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}不能是空白字符(纯空格、制表符等)`))
  }
  checkLength(label, value, callback, min, max)
  if (!exp2.test(value)) {
    return callback(new Error(`${label}不支持特殊字符`))
  }
}

/**
 * 校验路径，菜单路径、路由路径:支持形如：sys/menu、/home、static/img/、/home/index/menu、index
 */
export const checkPath = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkPathCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkPathCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}
const checkPathCore = function (label, rule, value, callback, min, max) {
  const exp = /^([/]?[A-Za-z0-9-]+[/]?)+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}格式有误，请输入形如：/aaa或bbb/cc`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 校验路径，组件路径:支持形如：/home/index/menu
 */
export const checkComponentPath = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkComponentPathCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkComponentPathCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}
const checkComponentPathCore = function (label, rule, value, callback, min, max) {
  const exp = /^([A-Za-z0-9-]+[/][A-Za-z0-9-]+)+$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}格式有误，请输入形如：aaa/bbb`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 校验手机号
 */
export const checkPhoneNumber = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkPhoneNumberCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkPhoneNumberCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}
const checkPhoneNumberCore = function (label, rule, value, callback, min, max) {
  const exp = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  if (!exp.test(value)) {
    return callback(new Error(`${label}格式有误`))
  }
  checkLength(label, value, callback, min, max)
}

/**
 * 校验邮箱
 */
export const checkEmail = function (checkNull, label, rule, value, callback, min, max) {
  if (checkNull) {
    if (!value) {
      return callback(new Error(`请输入${label}`))
    }
    checkEmailCore(label, rule, value, callback, min, max)
  } else if (value) {
    checkEmailCore(label, rule, value, callback, min, max)
  } else {
    return callback()
  }
  return callback()
}
const checkEmailCore = function (label, rule, value, callback, min, max) {
  const exp = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
  if (!exp.test(value)) {
    return callback(new Error(`${label}格式有误`))
  }
  checkLength(label, value, callback, min, max)
}
