<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      v-if="showMenu"
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden;"
      v-model="htmlContent"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onChange="handleOnChange"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'TgEditor',
  components: { Editor, Toolbar },
  props: {
    showMenu: {
      type: Boolean,
      default: () => { return true }
    },
    mode: {
      type: String,
      default: () => { return 'default' }
    },
    toolbarConfig: {
      type: Object,
      default: () => { return {} }
    },
    editorConfig: {
      type: Object,
      default: () => { return {} }
    },
    html: {
      type: String,
      default: () => { return '' }
    }
  },
  data() {
    return {
      editor: null,
      htmlContent: ''
    }
  },
  watch: {
    html(nVal) {
      this.htmlContent = nVal
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    handleOnChange(val) {
      this.$emit('onEditorChange', { editor: val, html: this.htmlContent })
    }
  },
  mounted() {
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style scoped>
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
